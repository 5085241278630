$green: #d1fb54;
$yellow: #fffc66;
$pink: #e45a84;
// $green: #87e550;
// $yellow: #f6e05e;
// $pink: #ed64a6;
$colors: (#583c87, #e45a84, #ffacac);

$particleSize: 20vh;

.box {
  position: relative;

  width: $particleSize * 3;
  height: $particleSize * 3;
  // width: 100%;
  // height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  animation: box 60s linear 100ms infinite;

  .jelly {
    position: absolute;
    width: $particleSize * 2;
    height: $particleSize * 2;
    // width: 1px;
    // height: 1px;
    color: $green;
    background-color: $green;
    border-radius: 50%;
    -webkit-filter: blur(10vh);
    -moz-filter: blur(10vh);
    -o-filter: blur(10vh);
    -ms-filter: blur(10vh);
    filter: blur(10vh);

    // box-shadow: 0 0 $particleSize $particleSize currentColor;

    transform-origin: center center;
    animation: jelly 23s linear -5s infinite;

    &.yellow {
      color: $yellow;
      background-color: $yellow;
      animation-delay: -17s;
    }

    &.pink {
      color: $pink;
      background-color: $pink;
      animation-delay: -31s;
    }
  }
}

$startX: 0;
$startY: 0;

@keyframes box {
  0% {
    transform: translate($startX, $startY);
  }

  17% {
    transform: translate($particleSize * 1.5, $particleSize * 1.5);
  }

  33% {
    transform: translate($particleSize * 4, $particleSize * 2);
  }

  50% {
    transform: translate($particleSize * 4, $startY);
  }

  67% {
    transform: translate($particleSize * 1.5, $particleSize * 1.5);
  }

  84% {
    transform: translate($startX, $particleSize * 2);
  }

  100% {
    transform: translate($startX, $startY);
  }
}

@keyframes jelly {
  0% {
    transform: scale(1) rotate(0) translate(0, 0);
    // box-shadow: 0 0 $particleSize $particleSize currentColor;
  }

  25% {
    transform: scale(1.2, 1);
  }

  50% {
    transform: scale(1.4, 0.8) rotate(180deg) translate($particleSize * (0.8 + random()), $particleSize * (0.8 + random()));
    // box-shadow: 0 0 $particleSize * 0.8 $particleSize * 0.8 currentColor;
  }

  75% {
    transform: scale(1.1, 1.2);
  }

  100% {
    transform: scale(1) rotate(360) translate(0, 0);
    // box-shadow: 0 0 $particleSize $particleSize currentColor;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.background {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  // background-color: black;
  position: fixed;
  padding: 10vh;
  top: 0;
  left: 0;
  z-index: -1;
}

@media only screen and (max-width: 760px) {
  $particleSize: 10vw;

  .background {
    padding: 0;

    .box {
      width: $particleSize * 2;
      height: $particleSize * 2;
      animation: box 30s linear 100ms infinite;
    }
  }
}

.link {
  cursor: pointer;
  position: relative;
  white-space: normal;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;

  .normal,
  .rotate {
    text-align: center;
    transition: animation 300ms;
    transition-timing-function: cubic-bezier(0.6, 1.48, 0.34, -0.77);
  }

  .normal {
    position: relative;
    animation: normalaReverse 1s ease-in forwards;
  }

  .rotate {
    position: absolute;

    display: none;
    transform: translateX(110%) rotateY(180deg);
    animation: rotateaReverse 1s ease-in forwards;
  }

  &:hover {
    .normal {
      animation: normala 1s ease-in forwards;
    }

    .rotate {
      display: block;
      animation: rotatea 1s ease-in forwards;
    }
  }
}

@keyframes normala {
  from {
    transform: translateX(0);
  }

  to {
    display: none;
    transform: translateX(-110%);
    animation-play-state: paused;
  }
}

@keyframes normalaReverse {
  from {
    display: none;
    transform: translateX(-110%);
    animation-play-state: paused;
  }

  to {
    transform: translateX(0);
  }
}

@keyframes rotatea {
  from {
    display: block;
    transform: translateX(110%) rotateY(180deg);
  }

  to {
    transform: translateX(0) rotateY(180deg);
    animation-play-state: paused;
  }
}

@keyframes rotateaReverse {
  from {
    transform: translateX(0) rotateY(180deg);
    animation-play-state: paused;
  }

  to {
    display: none;
    transform: translateX(110%) rotateY(180deg);
  }
}