:root {
  --black: #000;
  --white: #fff;
  --pink: #ed0087;
  --blue: #2200f6;
  --orange: #ff742f;
  --green: #c6f800;
}

.drawer-list li .active-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.05em;
  background-color: white;
  // transform: translatex(0);
  // -ms-transform: translatex(-100%);
}

input.hamburger:checked ~ .drawer-list li .active-link::after {
  animation: underLine 3s ease;
  // transition: transform 4000ms;
  // transform: translatex(0);
  // -ms-transform: translatex(0);
}

@-webkit-keyframes underLine {
  from {
    transform: translatex(-100%);
  }

  to {
    transform: translatex(0);
  }
}

@keyframes underLine {
  from {
    transform: translatex(-100%);
  }

  to {
    transform: translatex(0);
  }
}

.smooth-enter {
  opacity: 0;

  > .popup > .popup-content {
    margin: 181px auto;
    overflow: hidden;
  }
}

.smooth-enter-active {
  opacity: 1;

  > .popup > .popup-content {
    margin: 81px auto;
  }
}

.smooth-exit {
  opacity: 1;
}

.smooth-exit-active {
  opacity: 0;
}

.smooth-enter-active,
.smooth-exit-active {
  transition: opacity 500ms, transform 500ms;

  > .popup > .popup-content {
    transition: margin 500ms;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --black: #fff;
    --white: #000;
  }
}

@-moz-document url-prefix() {
  html {
    scrollbar-width: none;
  }

  .popup {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

* {
  transition: background-color 200ms;
}

body {
  font-display: swap;
  font-family: "HalvarBreitschrift", sans-serif;
  font-weight: 300;
  font-style: normal;
  width: 100vw;
  min-height: 100vh;
  background: var(--white);
  font-size: 24px;
  line-height: 27px;
  min-width: 320px;
  color: var(--black);
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: "HalvarBreitschrift";
  src: url("../public/fonts/Halvar-Breitschrift-Thin-Web.eot?") format("eot"),
    url("../public/fonts/Halvar-Breitschrift-Thin-Web.woff") format("woff"),
    url("../public/fonts/Halvar-Breitschrift-Thin-Web.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

.mainWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}

.center {
  flex: 1;
  align-items: center;
  justify-content: center !important;
}

header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 40px 0 0 0;
  position: fixed;
  z-index: 9999;
}

footer {
  display: flex;
  justify-content: center;
}

.footer-content {
  width: 80%;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}

.footer-block1,
.footer-block2,
.footer-block3,
.footer-copirite {
  width: 24%;
  /*font-weight: 600;*/
}

a {
  color: var(--black);
}

h1 {
  font-size: 107px;
  font-weight: 300;
  line-height: 124px;
  margin: 0;
  min-height: 30vh;
  display: flex;
  align-items: center;
}

.page-title > h2 {
  font-size: 3.5vw;
  line-height: 66px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 30px;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  background-color: var(--pink);
  // cursor: none;
}

.page-title h2 {
  background: linear-gradient(
    to right,
    var(--blue) 27.71%,
    var(--orange) 76.88%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 5s ease infinite;
  background-size: 400% 400%;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

h3 {
  font-size: 36px;
  line-height: 41px;
  font-weight: 300;
  margin-top: 0;
}

p,
ul {
  margin: 0;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

.container-content {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 196px 0 218px;
}

.container {
  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: center;
}

.logo {
  z-index: 9999;
  padding-left: 40px;

  // width: 12vw;
  // height: 4vw;
  // position: relative;
}

/*-------SITEBAR-LEFT-------*/
.sitebar-left {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: calc(100vh - 98px);
}

.page-title {
  height: 100%;
  display: flex;
  padding-top: 95px;
}

/*-------END SITEBAR-LEFT-------*/
/*-------SITEBAR-RIGHT-------*/
.sitebar-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  position: fixed;
  right: 0;
  padding-right: 20px;
  top: 0;
}

.navigation-menu {
  display: flex;
  padding-right: 18px;
}

/*------button-moon--------*/
.button-moon {
  display: none;
}

.button-moon-for {
  cursor: pointer;
  font-size: 16px;
  line-height: 26px;
  position: relative;
}

.button-moon-for::after {
  content: "";
  width: 40px;
  height: 22px;
  border: 1px solid var(--black);
  background-color: var(--black);
  border-radius: 100px;
  position: absolute;
  top: 14px;
  right: 44px;
}

@-moz-document url-prefix() {
  .button-moon-for::after {
    top: 27px;
  }
}

.button-moon-for::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--white);
  position: absolute;
  top: 17px;
  right: 65px;
  z-index: 1;
  transition-duration: 300ms;
}

@-moz-document url-prefix() {
  .button-moon-for::before {
    top: 30px;
  }
}

.button-moon:checked + .button-moon-for::before {
  right: 47px;
  background-color: var(--white);
}

.button-moon:checked + .button-moon-for::after {
  border: 1px solid white;
  background-color: white;
}

/*----------end-button-moon--------------*/

/*-------END SITEBAR-RIGHT-------*/
footer {
  margin-bottom: 30px;
}

.footer-block2 li {
  text-align: center;
  a,
  span {
    cursor: pointer;
  }
}

.footer-block3 a {
  background-color: var(--pink);
  background-image: linear-gradient(
    -90deg,
    var(--green) -140.2%,
    var(--pink) 100%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

  .footer-block2 li, .footer-block2 a {
    text-align: left;
    color: var(--pink)!important;
}
.about-us h2 {
  background-color: var(--pink);
  background: linear-gradient(to right, var(--blue), var(--orange));
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 5s ease infinite;
  background-size: 400% 400%;
}

.content {
  width: 1520px;
}

/*------------our-services------*/
.our-services .content p,
.our-vacancy .content p,
.our-vacancy .content .vacancy div {
  margin-bottom: 104px;
}

.our-services .content,
.our-vacancy .content,
.about-us .content {
  width: 750px;
}

.our-services.container-content,
.our-vacancy.container-content {
  padding: 162px 0 218px;
}

.services h2 {
  background-color: var(--green);
  background: linear-gradient(to right, var(--green), var(--pink));
  animation: rainbow 5s ease infinite;
  background-size: 400% 400%;

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services-menu {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  margin: 116px 0;
  overflow-y: auto;
  max-height: calc(100vh - 232px);
}

.services-menu a {
  display: block;
  margin-bottom: 23px;
  background-color: var(--blue);
  background-image: linear-gradient(
    269.54deg,
    var(--blue) 27.71%,
    var(--orange) 68.88%
  );
  font-size: 18px;
  transition: background 0s;
  max-width: 200px;
  cursor: pointer;

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services-menu a.active,
.services-menu a:hover {
  background-color: var(--black);
  background-image: none;
}

.our-services .content {
  .article {
    p {
      margin-bottom: 0;
    }

    padding-bottom: 40px;
  }
}

/*---------vacancies-------------*/
.vacancies h2 {
  background-color: var(--pink); //#d50092;
  background: linear-gradient(0deg, var(--pink) 27.71%, var(--orange) 68.88%);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sitebar-right.bar-vacancy {
  justify-content: center;
}

.vacancy li {
  &::after {
    content: ";";
  }

  &:last-of-type {
    &::after {
      content: ".";
    }
  }
}

.vacancies-name {
  // display: inline-block;
  display: -webkit-box !important;
  max-width: 200px;
  cursor: pointer;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.active {
    cursor: default;
  }
}

.vacancy-title > a {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.our-vacancy .apply {
  width: fit-content;
  margin: 100px auto 0;
  background-color: var(--blue); //#d50092;
  background: linear-gradient(270deg, var(--blue) 27.71%, var(--orange) 68.88%);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  cursor: pointer;
}

/*--------------portfolio-------------------*/
.portfolio .content {
  width: 1520px;
  display: flex;
  justify-content: space-between;
}

.container-content.portfolio,
.container-content.blog {
  flex-wrap: wrap;
  padding: 168px 0 218px;
}

.portfolio .row {
  margin-bottom: 80px;
  justify-content: space-between;
}

.grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 80px;
  column-gap: 15px;
}

.project {
  cursor: pointer;
}

.project-title {
  font-size: 18px;
  margin: 10px 0;
  color: var(--black);
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.project-img {
  height: 276px;
  position: relative;
  width: 370px;

  img {
    width: 100%;
    border-radius: 50px;
    height: 100%;
    object-fit: cover;
  }
}

.blog-img {
  position: relative;
  height: 560px;
  /*aspect-ratio: 4/3;*/
  // padding-top: 75%;
  // margin-bottom: 30px;
}

/*--------------blog-------------------*/

.blog-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 10px;
}

.blog-title,
.blog p {
  color: var(--black);
}

.blog-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 10px;
}

.blog p {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}
.no-posts,
.not-vacancies{
  width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
.no-posts p,
.not-vacancies p{
  text-align: center;
  background-color: var(--green);
  background: linear-gradient(to right, var(--green), var(--pink));
  -webkit-animation: rainbow 5s ease infinite;
  animation: rainbow 5s ease infinite;
  background-size: 400% 400%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.5vw;
  line-height: 4vw;
} 
.blog-primary {
  cursor: pointer;
  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }
}

.blog-primary,
.blog-img img {
  width: 100%;
  border-radius: 50px;
  height: 100%;
  object-fit: cover;
}

.primary {
  margin-bottom: 0 !important;
}

/*--------------menu-------------------*/
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.features {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}

.features li {
  width: 100%;
  text-align: center;
  padding: 0.25em;
}

.drawer-list {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  transform: translate(100vw, 0);
  /* ie workaround */
  -ms-transform: translatex(-100vw);
  box-sizing: border-box;
  // pointer-events: none;
  padding-top: 80px;
  transition: width 475ms ease-out, transform 450ms ease,
    border-radius 0.8s 0.1s ease;
  border-bottom-left-radius: 100vw;
  background: #0025f0;
  background: radial-gradient(circle, var(--blue), var(--pink), var(--blue));
  background: radial-gradient(circle, #0025f0, #eb3574, #0025f0);
  background-size: 200% 600%;
  background-repeat: no-repeat;
  animation: gradient 15s ease infinite;
  z-index: 2;
}

@keyframes gradient {
  0% {
    background-position: 10% 50%;
  }

  50% {
    background-position: 80% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.drawer-list ul {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-x: hidden;
  pointer-events: auto;
}

.drawer-list li {
  list-style: none;
  text-transform: uppercase;
  pointer-events: auto;
  white-space: nowrap;
  box-sizing: border-box;
  transform: translatex(100vw);
  /* ie workaround */
  -ms-transform: translatex(-100vw);
}

.drawer-list > .lang li a {
  font-size: 24px;
  margin-top: 42px;
  cursor: pointer;

  &.lang-button {
    padding: 0 15px;
  }
}

.drawer-list li:last-child {
  margin-bottom: 2em;
}

.drawer-list li a {
  color: white;
  font-size: 72px;
  line-height: 104px;
}

.drawer-list > ul {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 80%;
}

.contrary {
  /* direction: rtl; */
  /* unicode-bidi: bidi-override; */
  transform-origin: center center;
  transform: rotateY(180deg);
}

.lang ul {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.lang li:last-child {
  list-style: disc;
}

.lang li {
  cursor: pointer;
  color: white;
  text-align: center;
  display: block;
  font-size: 24px;
  line-height: 28px;
  text-transform: none;
}

input.hamburger {
  display: none;
}

input.hamburger:checked ~ .drawer-list {
  transform: translatex(0);
  border-bottom-left-radius: 0;
}

input.hamburger:checked ~ .drawer-list li {
  transform: translatex(0);
  display: flex;
  justify-content: center;
}

input.hamburger:checked ~ .drawer-list > .lang li {
  transition: transform 1s;
}

input.hamburger:checked ~ .drawer-list > ul > li:nth-child(1) {
  transition: transform 1s;
}

input.hamburger:checked ~ .drawer-list > ul > li:nth-child(2) {
  transition: transform 1.2s;
}

input.hamburger:checked ~ .drawer-list > ul > li:nth-child(3) {
  transition: transform 1.4s;
}

input.hamburger:checked ~ .drawer-list > ul > li:nth-child(4) {
  transition: transform 1.6s;
}

input.hamburger:checked ~ .drawer-list > ul > li:nth-child(5) {
  transition: transform 1.8s;
}

input.hamburger:checked ~ .drawer-list > ul > li:nth-child(6) {
  transition: transform 2s;
}

input.hamburger:checked ~ .drawer-list > ul > li:nth-child(7) {
  transition: transform 2.2s;
}

// input.hamburger:checked ~ .drawer-list li a {
//   padding-right: 10px;
// }
input.hamburger:checked ~ .drawer-list ul .lang-button {
  margin: 0;
  margin: 0 15px;
  position: relative;
  border-bottom: 1px solid transparent;

  &:hover,
  &.active {
    border-bottom-color: white;
  }

  &:not(:last-of-type) {
    &::after {
      content: "•";
      position: absolute;
      bottom: 0px;
      right: -22px;
      font-size: 1.5em;
      color: white;
    }
  }

  a {
    padding: 0;
  }
}

// input.hamburger:checked~label>i {
//   background-color: transparent;
//   transform: rotate(90deg);
// }

// input.hamburger:checked~label>i:before {
//   transform: translate(-50%, -50%) rotate(315deg);
// }

// input.hamburger:checked~label>i:after {
//   transform: translate(-50%, -50%) rotate(-315deg);
// }

label.hamburger span:nth-child(1) {
  top: 5px;
}

label.hamburger span:nth-child(2),
label.hamburger span:nth-child(3) {
  top: 20px;
}

label.hamburger span:nth-child(4) {
  top: 35px;
}

input.hamburger:checked ~ label > span:nth-child(1) {
  top: 15px;
  width: 0%;
  left: 50%;
}

input.hamburger:checked ~ label > span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

input.hamburger:checked ~ label > span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input.hamburger:checked ~ label > span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

label.hamburger {
  z-index: 9999;
  position: relative;
  display: block;
  height: 18px;
  width: 25px;
}

label.hamburger:hover {
  cursor: pointer;
}

label.hamburger > span {
  position: absolute;
  width: 100%;
  height: 2px;
  top: 50%;
  background-color: var(--black);
  pointer-events: auto;
  transition-duration: 0.35s;
  transition-delay: 0.35s;
}

label.hamburger {
  width: 25px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

label.hamburger > span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

// label.hamburger>i:before,
// label.hamburger>i:after {
//   position: absolute;
//   display: block;
//   width: 100%;
//   height: 2px;
//   left: 50%;
//   background-color: var(--black);
//   content: '';
//   transition: transform 0.35s;
//   transform-origin: 50% 50%;
// }

// input.hamburger:checked~label>i:before,
// input.hamburger:checked~label>i:after {
//   background-color: white;
// }

// label.hamburger>i:before {
//   transform: translate(-50%, -14px);
// }

// label.hamburger>i:after {
//   transform: translate(-50%, 14px);
// }

label.hamburger {
  top: 0px;
  right: 0px;
}

/*--------PopUp-------*/
.popup-checkbox,
.popup {
  display: none;
}

.vacancy-name {
  font-size: 36px;
  line-height: 42px;
  // padding: 40px 60px 20px 0;
  padding-right: 20px;
}

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 9999;
}

.popup {
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  display: block;
  z-index: 1;
}

.popup:before {
  display: block;
  content: " ";
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
}

.popup-content-blog {
  // height: max-content;
  // text-align: center;
  // padding: 100px 128px;
  width: 30%;
  height: max-content;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 65px auto;
  background-color: var(--white);
  border-radius: 100px;
  padding: 100px 128px 100px;
}

.project-img img {
  background: #fff;
}

.popup-view-img {
  display: none;
}

.popup-content-portfolio {
  height: auto;
  text-align: center;
  padding: 100px 128px;
  width: 1200px;
}

.popup-content-portfolio {
  padding: 0 !important;
}

.popup-content-blog h3 {
  text-align: left;
  margin-top: 100px;
}

.popup-content {
  width: 1007px;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 81px auto;
  background-color: var(--white);
  border-radius: 100px;
  padding: 100px 128px 100px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &-access {
    margin: 81px auto;
    justify-content: space-around;

    &-title {
      margin: 0 0 60px 0;
      font-size: 72px;
      line-height: 83px;
      text-align: center;
      background-color: var(--pink);
      background-image: -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(-140.2%, var(--pink)),
        to(var(--green))
      );
      background-image: -webkit-linear-gradient(
        right,
        var(--pink),
        var(--green) 100%
      );
      background-image: linear-gradient(-90deg, var(--pink), var(--green) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &-text {
      font-style: normal;
      font-weight: 300;
      font-size: 28px;
    }
  }

  & > * {
    zoom: 0.6;
  }
}

.popup-shower {
  color: #00f;
  cursor: pointer;
  text-decoration: underline;
}

.popup-shower:hover {
  color: #00a;
  text-decoration: underline;
}

.popup-closer {
  text-shadow: 1px 1px 10px black;
  position: absolute;
  top: -10px;
  right: -10px;
  // top: 75px;
  // right: 75px;
  color: var(--black);
  font-size: 40px;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  padding-left: 1px;
  padding-top: 2px;
  z-index: 1;
}

.popup-closer:hover {
  background-color: #eee;
}

.popup-view {
  min-height: 100%;
  height: 100%;
  overflow: auto;
  z-index: -1;

  &-contactUs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }
  }

  &-blog {
    &-img {
      width: 100%;
      height: auto;
    }

    &-title {
      margin: 100px 0;
      font-size: 72px;
      line-height: normal;
      text-align: left;
      background-color: var(--pink);
      background-image: -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(-140.2%, var(--green)),
        to(var(--pink))
      );
      background-image: -webkit-linear-gradient(
        right,
        var(--green) -140.2%,
        var(--pink) 100%
      );
      background-image: linear-gradient(
        -90deg,
        var(--green) -140.2%,
        var(--pink) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      word-break: break-all;
    }

    &-text {
      text-align: left;
      white-space: pre-line;
      word-break: break-all;
    }
  }

  &-portfolio {
    border-radius: inherit;

    &-img {
      width: 100%;
      height: auto;
    }

    img {
      width: 100%;
      height: auto;
      display: flex;
    }

    &-title {
      margin: 100px 0;
      font-size: 72px;
      line-height: normal;
      text-align: left;
      background-color: var(--pink);
      background-image: -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(-140.2%, var(--green)),
        to(var(--pink))
      );
      background-image: -webkit-linear-gradient(
        right,
        var(--green) -140.2%,
        var(--pink) 100%
      );
      background-image: linear-gradient(
        -90deg,
        var(--green) -140.2%,
        var(--pink) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &-title {
      word-break: break-all;
    }

    &-text {
      text-align: left;
      word-break: break-all;
    }
  }

  &-img {
    font-size: 0;
    line-height: 0;
  }
}

.addres-contact {
  margin-bottom: 60px;
}

.addres-contact-content h3 {
  background: linear-gradient(60deg, var(--blue) 27.71%, var(--orange) 76.88%);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 68px;
  line-height: 83px;
  text-align: center;
  margin-bottom: 60px;
}

.without-background-popup {
  background: none;
}

.popup-content-blog {
  height: auto;
  text-align: center;
  padding: 100px 128px;
}

.popup-content-blog h3 {
  text-align: left;
  margin-top: 100px;
}

.popup-content-portfolio {
  height: auto;
  text-align: center;
  padding: 100px 128px;
  width: 1400px;
}

.popup-content-portfolio h3 {
  text-align: left;
  margin-top: 100px;
}

.scrollable {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

.form-row {
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
}

.form-row input {
  color: var(--black);
  display: block;
  width: 100%;
  padding: 0 10px;
  background: none;
  font-size: 24px;
  font-family: "HalvarBreitschrift", sans-serif;
  font-weight: 300;
  font-style: normal;
  border-width: 0;
  border: transparent;
  border-radius: 0;
  border-bottom: 1px solid var(--black);
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.form-row label {
  position: absolute;
  left: 13px;
  top: 10px;
  color: var(--black);
  font-size: 24px;
  font-weight: 300;
  transform: translateY(-35px);
  transition: all 0.2s ease;
}

.form-row input:focus {
  outline: 0;
  border-color: #f77a52;
}

.form-row input:focus + label,
.form-row input:not(:placeholder-shown) + label {
  transform: translateY(-60px);
  margin-left: -14px;
  font-size: 14px;
  font-weight: 400;
  outline: 0;
  border-color: var(--orange);
  color: var(--orange);
}

.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
}

.btn button {
  color: var(--black);
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 36px;
  font-family: "HalvarBreitschrift", sans-serif;
  cursor: pointer;
}

.btn2-span {
  background: linear-gradient(60deg, var(--blue) 27.71%, var(--orange) 76.88%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.btn .btn2 {
  margin-left: 20px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#document {
  display: none;
}

.add-document label {
  font-weight: 300;
  font-size: 24px;
  color: var(--blue);
  cursor: pointer;
}

.selected-document {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  &-text {
    display: -webkit-box;
    width: 80%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-cross {
    cursor: pointer;
    font-size: 40px;
  }
}

.popup-view-portfolio-text {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
  img {
    min-height: 400px;
  }
  .lazy{
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);
  }
}

.popup-content,
.popup-content-access {
  margin: 50px auto;
}

// .content {
//   position: relative;
//   padding: 1em;
// }
// .content::before {
//   content: '';
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   background-color: var(--white);
//   opacity: 0.2;
//   border-radius: 1em;
// }
