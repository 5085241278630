@media only screen and (max-width: 1800px) {
  .portfolio .content,
  .blog .row.primary {
    width: 1020px;
  }

  .blog-primary {
    width: 500px;
  }

  .project-img {
    height: 170px;
    position: relative;
    width: 230px;
  }

  .content {
    width: 80vw;
  }

  .blog-primary > .blog-img {
    height: 250px;
  }
}

@media only screen and (max-width: 1540px) {
  /*1370*/
  .container-content {
    padding: 100px 0 100px;
  }

  .blog-primary {
    width: 450px;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }
  }

  .simpli .content {
    display: flex;
    justify-content: center;
  }

  h1 {
    font-size: 67px;
    line-height: 78px;
  }

  .page-title > h2 {
    font-size: 36px;
    line-height: 42px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 0;
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    margin-top: 140px;
  }

  h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .sitebar-left {
    display: flex;
    flex-direction: row;
    position: relative;
    width: inherit;
    justify-content: center;
    height: auto;
    padding-top: 11px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .our-services.container-content,
  .container-content.about-us {
    padding: 54px 0 218px;
  }

  .our-services .content p,
  .about-us .content p,
  .our-vacancy .content p,
  .our-vacancy .content .vacancy div,
  ul {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 40px;
  }

  .services h2 {
    background-color: var(--blue);
    background-image: linear-gradient(
      269.54deg,
      var(--blue) 27.71%,
      var(--orange) 68.88%
    );
  }

  .project-img {
    height: 170px;
    position: relative;
    width: 230px;
  }

  .container-content.portfolio,
  .container-content.blog {
    padding: 70px 0;
  }
}

@media only screen and (max-width: 1440px) {
  .popup-content {
    width: 80vw;
    padding: 55px 100px;
  }

  .our-services .content,
  .our-vacancy .content,
  .about-us .content {
    width: 570px;
  }

  h1 {
    width: 960px;
  }

  .content,
  .footer-content,
  .portfolio .content {
    width: 960px;
  }

  .drawer-list li a {
    font-size: 46px;
    line-height: 80px;
  }

  .drawer-list li.lang-button a {
    line-height: 28px;
  }

  .services-menu {
    width: 165px;
  }
}

@media only screen and (max-width: 960px) {
  .popup-content {
    width: 680px;
  }

  header {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  .content,
  .footer-content,
  .portfolio .content,
  .blog .row.primary {
    width: 740px;
  }

  .sitebar-right {
    display: none;
  }

  .project-img {
    height: 124px;
    position: relative;
    width: 175px;
  }

  .blog-primary {
    width: 340px;

    > .blog-img {
      height: 200px;
    }
  }

  .drawer-list {
    padding-left: 1em;
    padding-right: 1em;
  }

  .drawer-list li a {
    font-size: 34px;
    line-height: 50px;
  }

  .container-content {
    padding: 180px 0 100px;
  }

  .popup-content-access {
    &-title {
      font-size: 46px;
      line-height: normal;
    }

    &-text {
      font-size: 32px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .smooth-enter > .popup > .popup-content {
    margin: 0;
  }

  .smooth-enter-active > .popup > .popup-content {
    margin: 0;
  }

  .vacancy-name {
    word-break: break-word;
  }

  .grid {
    align-content: space-between;
    grid-template-columns: auto auto;
    justify-content: space-between !important;
  }

  .popup-view-blog {
    &-title {
      font-size: 62px;
      margin: 50px 0;
    }
  }

  .popup-view-portfolio {
    &-title {
      font-size: 62px;
      margin: 50px 0;
    }
  }

  ul {
    margin-bottom: 0;
  }

  .content,
  .footer-content {
    width: 84%;
  }

  h1 {
    width: 84vw;
  }

  .portfolio .content {
    width: 84%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .blog .content {
    width: 100%;
  }

  .project {
    height: 170px;
    width: 40vw;
  }

  .project-img {
    height: 170px;
    position: relative;
    width: 100%;
  }

  .blog-primary {
    > .blog-img {
      height: 300px;
    }
  }

  .blog-primary p {
    display: none;
  }

  .portfolio .project {
    // margin-bottom: 80px;
  }

  .portfolio .row {
    // margin-bottom: 0;
    width: 84%;
    row-gap: 80px;
  }

  .our-services .content,
  .our-vacancy .content,
  .about-us .content {
    width: 84%;
  }

  // .our-services .content,
  // .our-vacancy .contentб .about-us .content {
  //   width: 318px;
  // }

  h1 {
    font-size: 37px;
    line-height: 43px;
  }

  .our-services.container-content,
  .container-content.about-us,
  .our-vacancy.container-content,
  .our-vacancy.container-content {
    padding: 54px 0 60px;
  }

  .blog-primary {
    width: 100%;
  }

  .blog .row.primary {
    flex-direction: column;
    width: 100%;
  }

  .blog-primary {
    margin-bottom: 40px;
  }

  .popup-closer {
    top: 50px;
    right: 29px;
  }

  .btn {
    flex-direction: column-reverse;
    margin-top: 60px;
  }

  .btn .btn2 {
    margin-bottom: 15px;
  }

  .addres-contact-content h3 {
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
  }

  .popup-content {
    width: 100vw;
    height: 100vh;
    padding: 55px 48px;
    margin: 0;
    border-radius: 0;

    &-blog {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0;
    }

    &-portfolio {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0;
    }
  }

  .address-contact {
    font-size: 18px;
  }

  .project-title {
    margin: 10px;
  }

  .page-title > h2 {
    margin-top: 27px;
  }

  // h1 {
  //   padding-left: 30px;
  // }
}

@media only screen and (max-width: 460px) {
  .drawer-list > ul {
    height: 75%;
  }
  .project {
    height: 100%;
    width: 100%;
  }

  .portfolio .row {
    margin-bottom: 30px;
  }

  header {
    padding-top: 20px;
  }

  h1 {
    width: 318px;
  }

  h3 {
    word-break: break-all;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .scrollable {
    padding: 0;
  }

  .popup-view-blog {
    &-title {
      font-size: 48px;
      margin: 50px 0;
    }
  }

  .popup-view-portfolio {
    &-title {
      font-size: 48px;
      margin: 50px 0;
    }
  }

  .project-img {
    height: 280px;
    width: 100%;
    margin: 0;
  }

  .blog-primary > .blog-img {
    height: auto;
  }

  .blog-img {
    img {
      height: 280px;
    }
  }

  .blog-primary {
    margin: 0;

    .blog-title {
      font-size: 18px;
    }
  }

  .portfolio {
    padding: 0;
  }

  .our-services .content,
  .about-us .content,
  .our-vacancy .content {
    width: 84vw;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-block1,
  .footer-block2,
  .footer-block3,
  .footer-copirite {
    margin-bottom: 40px;
    width: auto;
  }

  .footer-copirite {
    width: 50%;
  }

  .our-services.container-content,
  .container-content.about-us,
  .our-vacancy.container-content {
    padding: 14px 0 60px;
  }

  .popup-content-access {
    &-title {
      word-break: break-word;
      font-size: 42px;
      line-height: normal;
    }

    &-text {
      word-break: break-word;
      font-size: 26px;
    }
  }

  // input.hamburger:checked~.drawer-list li:nth-child(1) {
  //   transition: transform 1.5s;
  // }

  // input.hamburger:checked~.drawer-list li:nth-child(2) {
  //   transition: transform 1.5s;
  // }

  // input.hamburger:checked~.drawer-list li:nth-child(3) {
  //   transition: transform 1.8s;
  // }

  // input.hamburger:checked~.drawer-list li:nth-child(4) {
  //   transition: transform 2.1s;
  // }

  // input.hamburger:checked~.drawer-list li:nth-child(5) {
  //   transition: transform 2.5s;
  // }

  // input.hamburger:checked~.drawer-list li:nth-child(6) {
  //   transition: transform 2.8s;
  // }

  // input.hamburger:checked~.drawer-list li:nth-child(7) {
  //   transition: transform 3.1s;
  // }
}

@media only screen and (max-width: 320px) {
  .drawer-list > .lang li a {
    margin-top: 20px;
  }

  .lang ul {
    margin-bottom: 1em;
  }

  .content,
  .our-services .content,
  .about-us .content,
  .our-vacancy .content {
    width: 275px;
  }

  .popup-content > * {
    zoom: 0.6;
  }
}

@media only screen and (max-height: 420px) {
  header {
    padding-top: 20px;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-block1,
  .footer-block2,
  .footer-block3,
  .footer-copirite {
    margin-bottom: 20px;
    width: auto;
  }

  .drawer-list li a {
    font-size: 22px;
    line-height: 28px;
  }

  .drawer-list > .lang li a {
    line-height: 24px;
    font-size: 22px;
    margin-top: 0;
  }

  .btn {
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
  }

  .btn2 {
    margin: 0;
  }

  .address-contact-content {
    zoom: 0.5;
  }

  .address-contact {
    zoom: 0.5;
  }

  .popup-content {
    width: 100vw;
    height: 100vh;
    padding: 55px 100px;
    margin: 0;
    border-radius: 0;

    &-access {
      &-title {
        font-size: 50px;
        margin-bottom: 10px;
      }

      &-text {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
  }

  .popup-closer {
    top: 30px;
    right: 30px;
  }
}

@media only screen and (max-height: 320px) {
  // .our-services .content,
  // .our-vacancy .content,
  // .about-us .content {
  //   width: 430px;
  // }

  // .footer-content {
  //   width: 430px;
  // }

  // h1 {
  //   width: 430px;
  // }
  .drawer-list > ul {
    height: 90%;
  }

  .lang > ul {
    margin-bottom: 10px;
  }

  .popup-content {
    &-access {
      &-title {
        font-size: 36px;
        line-height: normal;
      }

      &-text {
        font-size: 24px;
      }
    }
  }
}
